"use strict";
const burger = document.querySelector(".burger");
const navbar = document.querySelector(".navbar");
const navLink = document.querySelectorAll(".nav-link");

/**
 * Бургер
 */

document.addEventListener(
  "DOMContentLoaded",
  burger.addEventListener("click", () => {
    navbar.classList.toggle("nav-open");
    burger.classList.toggle("burger-open");
    navLink.forEach((link) => {
      link.classList.toggle("nav-link-open");
    });
  })
);

document.addEventListener("DOMContentLoaded", function () {
  const form = document.getElementById("form");
  let formReq = document.querySelector("._req");

  //Events
  form.addEventListener("submit", sendForm);

  //Handlers
  async function sendForm(e) {
    e.preventDefault();

    let formData = new FormData(form);

    let error = formValidate(form);
    if (error === 0) {
      await fetch("/send.php", {
        method: "POST",
        body: formData,
      }).then((r) => {
        if (r.ok) {
          form.reset();
          alert("Сообщение успешно отправлено.");
        } else {
          alert("Ошибка попробуйте еще раз.");
        }
      });
    } else {
      alert("Заполните поле контакты!");
    }
  }

  function formValidate(form) {
    let error = 0;
    formRemoveError(formReq);
    if (formReq.value === "") {
      formAddError(formReq);
      error++;
    }
    return error;
  }

  function formAddError(input) {
    input.classList.add("_error");
  }
  function formRemoveError(input) {
    input.classList.remove("_error");
  }
});

var x, i, j, l, ll, selElmnt, a, b, c;
x = document.getElementsByClassName("custom-select");
l = x.length;
for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  ll = selElmnt.length;
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  for (j = 1; j < ll; j++) {
    c = document.createElement("DIV");
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener("click", function (e) {
      var y, i, k, s, h, sl, yl;
      s = this.parentNode.parentNode.getElementsByTagName("select")[0];
      sl = s.length;
      h = this.parentNode.previousSibling;
      for (i = 0; i < sl; i++) {
        if (s.options[i].innerHTML == this.innerHTML) {
          s.selectedIndex = i;
          h.innerHTML = this.innerHTML;
          y = this.parentNode.getElementsByClassName("same-as-selected");
          yl = y.length;
          for (k = 0; k < yl; k++) {
            y[k].removeAttribute("class");
          }
          this.setAttribute("class", "same-as-selected");
          break;
        }
      }
      h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function (e) {
    e.stopPropagation();
    closeAllSelect(this);
    this.nextSibling.classList.toggle("select-hide");
    this.classList.toggle("select-arrow-active");
  });
}

function closeAllSelect(elmnt) {
  var x,
    y,
    i,
    xl,
    yl,
    arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i);
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
document.addEventListener("click", closeAllSelect);

